import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import useMountedEffect from '@/core/hooks/useMountedEffect'
import { UtilService } from '@/core/services/util.service'
import { DataUtil } from '@/core/utils/data.util'
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useConciliationStore } from '../stores/conciliation.store'
import FileDropzone from '@/core/components/organism/FileDropzone'
import { BillingFiles } from '../models/conciliation.model'
import useCustomToast from '@/core/hooks/useCustomToast'


const BillingAddNewModal = () => {
  const { selectedWitnessesProvider, updateBillings } = useConciliationStore()
  const [files, setFiles] = useState<BillingFiles[]>([]);
  const toast = useCustomToast();

  const handleGenerateName = () => {
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' }); // Para obtener el mes completo en texto
    const year = currentDate.getFullYear();

    const monthYearString = `${month} ${year}`;
    loadFileForm.setFieldValue('name', DataUtil.capitalizeText(monthYearString))
  }

  const handleCreate = () => {
    updateBillings(selectedWitnessesProvider?.provider!, loadFileForm.values.name, files)
    toast.success('Factura añadida con éxito')
  }

  const loadFileForm = useFormik({
    onSubmit: handleCreate,
    validateOnBlur: true,
    initialValues: {
      name: '',
    }
  })

  const handleUpload = (file: File, type: string) => {
    const index = files?.findIndex(e => e.type === type)
    if (index === -1) {
      const newBilling = [
        ...files!,
        {
          file: file,
          type: type,
          status: 'Bajo Revisión',
          comments: 0,
          name: file.name
        }
      ]
      setFiles(newBilling)
    } else {
      const newBilling = files?.map(e => e.type === type ? {
        file: file,
        type: type,
        status: 'Bajo Revisión',
        comments: 0,
        name: file.name
      } : e)
      setFiles(newBilling)
    }
  }

  useMountedEffect(() => {
    handleGenerateName()
  })

  return (
    <CustomAlertDialog
      id="BillingAddNewModal"
      title={`Añadir nueva factura`}
      cancelText="Cancelar"
      acceptText="Añadir factura"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={loadFileForm.handleSubmit}
      size='4xl'
    >
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FormControl isInvalid={!!loadFileForm.errors.name} mb={1}>
            <FormLabel fontWeight={'normal'}>Nombre</FormLabel>
            <InputGroup>
              <Input id="name" type="text" placeholder="Nombre"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.name}</FormErrorMessage>
          </FormControl>
          <Flex justifyContent='space-between' columnGap={3}>
            <Box w='100%' mb={5}>
              <FormLabel fontWeight={'normal'}>PDF</FormLabel>
              <FileDropzone accept='application/pdf' onDropFile={(file) => handleUpload(file, 'pdf')} />
            </Box>
            <Box w='100%' mb={5}>
              <FormLabel fontWeight={'normal'}>XML</FormLabel>
              <FileDropzone accept='.xml' onDropFile={(file) => handleUpload(file, 'xml')} />
            </Box>
          </Flex>
          {/* <FormControl isInvalid={!!loadFileForm.errors.evidences} mb={1}>
            <FormLabel fontWeight={'normal'}>Número de evidencias</FormLabel>
            <InputGroup>
              <Input id="evidences" type="number" placeholder="Número de evidencias"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.evidences}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.evidences}</FormErrorMessage>
          </FormControl> */}
        </form>
      )}
    </CustomAlertDialog>
  )
}

export default BillingAddNewModal