import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import { useComponentsStore } from '@/core/stores/components.store'
import { Button, Flex, Text } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useModalStore } from '@/core/stores/modal.store'
import { useConciliationStore } from '../stores/conciliation.store'
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store'
import DocumentsCommentsView from './DocumentsCommentsView'

interface DocumentViewProps {
  title?: string
  isCarousel?: boolean
}

function DocumentsViewTemplate({ title, isCarousel = false }: DocumentViewProps) {
  const { uiConciliationComponents } = useComponentsStore()
  const { selectedFile, selectedBillingMonth } = useConciliationStore()
  const { selectedCampaign } = useCampaignStore()
  const { selectedConciliation } = useConciliationStore()
  const openModal = useModalStore(e => e.openModal);
  return (
    <>
      {
        ((!isCarousel && title === 'Factura') || (!isCarousel && title === 'Orden') || (!isCarousel && title === 'Carta')) ?
          <BrandPageHead subtitle={selectedFile?.name} type={title}>
            {(title === 'Factura' && uiConciliationComponents.approveBilling && selectedBillingMonth?.status === 'Bajo revisión') ? <Button mb={2} mr={2} mt={2} rightIcon={<CheckIcon />} onClick={() => openModal('Billingapprove')}>Aprobar factura</Button> :
              <Flex gap={2} alignItems={'center'}>
                <Text fontWeight="bold">Campaña:</Text>
                <Text>{selectedConciliation?.name}</Text>
              </Flex>}
          </BrandPageHead>
          :
          !isCarousel && <BrandPageHead subtitle={title}>
            <Flex gap={2} alignItems={'center'}>
              <Text fontWeight="bold">Campaña:</Text>
              <Text>{selectedConciliation?.name}</Text>
            </Flex>
          </BrandPageHead>
      }
      <DocumentsCommentsView type={title} isCarousel={isCarousel} />
    </>
  )
}

export default DocumentsViewTemplate