import React, { useEffect } from 'react'
import WitnessesFilters from './WitnessesFilters'
import { Box, SimpleGrid } from '@chakra-ui/react'
import WitnessesCards from './WitnessesCards'
import { useNavigate } from 'react-router-dom'
import { useComponentsStore } from '@/core/stores/components.store'
import ConciliationWitnessesPage from '../pages/ConciliationWitnessesPage'
import { WitnessesProviders } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'

function WitnessesPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents, getUiConciliationComponents } = useComponentsStore();
  const { witnessesProviders, setSelectedWitnessesProvider } = useConciliationStore();

  const handleWitness = (provider: string) => {
    setSelectedWitnessesProvider(provider)
    navigate('/views/reconciliations/witnesses')
  }

  useEffect(() => {
    getUiConciliationComponents()
    uiConciliationComponents.seeWitnessesProviders ? setSelectedWitnessesProvider('') : setSelectedWitnessesProvider('TV AZTECA')
    //setSelectedWitnessesProvider('')
  }, [])
  return (
    <>
      {
        uiConciliationComponents.seeWitnessesProviders ?
          <>
            <WitnessesFilters />
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
              {
                witnessesProviders.map((conciliation: WitnessesProviders, index) => (
                  <Box key={index} onClick={() => handleWitness(conciliation.provider!)}>
                    <WitnessesCards conciliation={conciliation} />
                  </Box>
                ))
              }
            </SimpleGrid>
          </>
          :
          <ConciliationWitnessesPage />
      }
    </>
  )
}

export default WitnessesPage