import BrandBox from '@/core/components/molecules/BrandBox'
import { useComponentsStore } from '@/core/stores/components.store'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Badge, Button, Flex, IconButton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BillingListPage from '../pages/BillingListPage'
import { useConciliationStore } from '../stores/conciliation.store'
import { ConciliationBilling } from '../models/conciliation.model'

interface RowConciliation {
  conciliation: ConciliationBilling
}


function BillingPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { conciliationBilling, setSelectedBillingProvider } = useConciliationStore();
  const handleClick = (provider: string) => {
    setSelectedBillingProvider(provider)
    navigate('/views/reconciliations/billing-list');
  }

  useEffect(() => {
    uiConciliationComponents.seeBillingProviders ? setSelectedBillingProvider('') : setSelectedBillingProvider('TV Azteca')
    //setSelectedWitnessesProvider('')
  }, [])
  const RowHover = ({ conciliation }: RowConciliation) => {
    return <Tr className='group hover:bg-blue-100'>
      <Td>
        <Flex columnGap={6}>
          <Text>
            {conciliation.provider}
          </Text>
        </Flex>
      </Td>
      <Td><Badge textTransform='capitalize' fontWeight='semibold' colorScheme='brand' rounded='xl' paddingX={2}>Facturas cargadas {conciliation.billingsByMonth.length}</Badge></Td>
      <Td isNumeric>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant='ghost'
          className='opacity-0 group-hover:opacity-100 transition-opacity'
          onClick={() => handleClick(conciliation.provider)}
        >Ver facturas</Button>
      </Td>
    </Tr>
  }

  return (
    uiConciliationComponents.seeBillingProviders ?
      <BrandBox>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th fontWeight='bold' color='black' fontSize='sm'>Proveedores</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                conciliationBilling.map((conciliation: ConciliationBilling, index: number) => {
                  return <RowHover key={index} conciliation={conciliation} />
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </BrandBox> :
      <BillingListPage />

  )
}

export default BillingPage