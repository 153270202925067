import { Box, Button, Flex, IconButton, Input, InputGroup, InputRightElement, Text, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiSend } from 'react-icons/fi'
import { RxDotsVertical } from 'react-icons/rx'
import CommentBody from './CommentBody'
import { DatesUtil } from '@/core/utils/dates.util'
import { Comment } from '../models/campaign.model'
import { useCampaignStore } from '../store/campaign.store'
import { useUserStore } from '@/modules/auth/stores/user.store'

interface CommentProps {
  comments?: Comment[]
  sendComments?: (comments: Comment[]) => void
}

const CommentsPanel = ({comments, sendComments}: CommentProps) => {
  const [comment, setComment] = useState<string>();
  const userInfo = useUserStore();
  const [commentsData, setCommentsData] = useState<Comment[]>([]);

  const addComment = () => {
    const currentDate = new Date();
    const parsedDate = DatesUtil.getDateToComment(currentDate);
    const body: Comment[] = [
      ...(comments || []), // Si `comments` es undefined, usa un array vacío
      {
        comment: comment ?? '',
        userId: userInfo.userData?.id,
        userName: `${userInfo.userData?.firstName} ${userInfo.userData?.lastName}`,
        date: DatesUtil.formatDateToString(new Date)
      }
    ];
    console.log(body)
    if(sendComments) sendComments(body)
    setComment('');
  }
  return (
    <Box w={'30%'}>
      <Box bgColor='gray.100' p={3} borderTopRadius='5px'>
        <Text fontSize="md" fontWeight="bold" mr="2">
          Comentarios
        </Text>
      </Box>
      <Box bgColor='white' height='50vh' p={3} overflowY={'auto'}>
        <Flex flexDirection='column' gap={2}>
          <>
            {comments?.map((item, index) => <CommentBody key={index} item={item} />)}
            {/* <CommentBody />
            <CommentBody />
            <CommentBody />
            <CommentBody /> */}
          </>
        </Flex>
      </Box>
      <Box bgColor='gray.100' p={3} borderBottomRadius='5px'>
        <InputGroup gap={1}>
          <Input placeholder="Comentario"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            colorScheme="brand"
            as={IconButton}
            icon={<FiSend size={20} />}
            onClick={addComment}
          >Enviar</Button>
        </InputGroup>
      </Box>
    </Box>
  )
}

export default CommentsPanel