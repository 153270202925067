import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import WitnessesCards from '../components/WitnessesCards';
import { useNavigate } from 'react-router-dom';
import { useComponentsStore } from '@/core/stores/components.store';
import { EvidencesByMonth } from '../models/conciliation.model';
import { useConciliationStore } from '../stores/conciliation.store';
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store';
import WinessesAddFolderModal from '../components/WinessesAddFolderModal';
import useMountedEffect from '@/core/hooks/useMountedEffect';

function ConciliationWitnessesPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { selectedWitnessesProvider, setSelectedWitnessesMonth, selectedConciliation } = useConciliationStore()
  const { selectedCampaign } = useCampaignStore()

  const handleWitnessMonths = (month: string) => {
    setSelectedWitnessesMonth(month)
    navigate('/views/reconciliations/witnesses/evidences')
  }

  useMountedEffect(() => {
    console.log(selectedConciliation, selectedWitnessesProvider, 'conciliacion');
  })

  return (
    <>
      {uiConciliationComponents.seeWitnessesProviders && <BrandPageHead title='Carpeta de' subtitle={selectedWitnessesProvider?.provider} >
        <Flex gap={2} alignItems={'center'}>
          <Text fontWeight="bold">Campaña:</Text>
          <Text>{selectedCampaign?.name}</Text>
        </Flex>
      </BrandPageHead>}
      <Flex justifyContent='space-between'>
        <Text fontSize='lg' fontWeight='semibold'>Carpetas de evidencias</Text>
        {/* <Button onClick={() => openModal('WinessesAddFolderModal')}>Crear carpeta</Button> */}
      </Flex>
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
        {
          selectedWitnessesProvider?.evidences?.map((conciliation: EvidencesByMonth, index: number) => (
            <Box onClick={() => handleWitnessMonths(conciliation.month!)} key={index}>
              <WitnessesCards conciliation={conciliation} index={index} />
            </Box>
          ))
        }
      </SimpleGrid>
      <WinessesAddFolderModal />
    </>
  )
}

export default ConciliationWitnessesPage