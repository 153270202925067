import CommentsPanel from '@/modules/campaigns/components/CommentsPanel'
import { CheckIcon } from '@chakra-ui/icons'
import { AspectRatio, Box, Button, Flex, Image } from '@chakra-ui/react'
import DocumentHead from '../../../core/components/molecules/DocumentHead'
import { useEffect, useState } from 'react'
import { useComponentsStore } from '@/core/stores/components.store'
import { BiUpload } from 'react-icons/bi'
import BillingapproveModal from './BillingApproveModal'
import { useModalStore } from '@/core/stores/modal.store'
import { useConciliationStore } from '../stores/conciliation.store'
import useMountedEffect from '@/core/hooks/useMountedEffect'

interface DocumentCommentsProps {
  document?: string
  type?: string
  isCarousel?: boolean
}

interface DocumentCommentsProps {
  document?: string;
  name?: string;
}


function DocumentsCommentsView({ document = '/campaign/campaña año nuevo brief.pdf', type, isCarousel }: DocumentCommentsProps) {
  const { uiConciliationComponents } = useComponentsStore();
  const { selectedFile, selectedWitnessesMonth } = useConciliationStore();
  const openModal = useModalStore(e => e.openModal);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [srcPdf, setSrcPdf] = useState<string>('');

  useEffect(() => {
    const index = selectedWitnessesMonth?.files?.findIndex(e => e.file === selectedFile?.file)
    setCurrentIndex(index!)
  }, [selectedFile])

  useMountedEffect(() => {
    if (selectedFile) {
      if (typeof selectedFile?.file === 'string') {
        setSrcPdf(selectedFile?.file)
      } else {
        const pdfurl = URL.createObjectURL(selectedFile?.file!); // Convierte el archivo en una URL

        setSrcPdf(pdfurl)
      }
    }
  })

  return (
    <Flex>
      <Box w={'70%'} textAlign='right' bg={'white'}>
        {isCarousel &&
          <DocumentHead lenghtData={selectedWitnessesMonth?.files?.length} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} nameFile={selectedWitnessesMonth?.files?.[currentIndex].name} />
        }
        {/* {(type === 'Factura' && uiConciliationComponents.addBillingFile) && <Button mb={2} mr={2} mt={2} rightIcon={<BiUpload />}>Cargar archivo</Button>} */}
        {selectedFile?.type === 'pdf' ? (
          <object type="application/pdf" data={srcPdf} width="100%" height="100%">
            <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={document}>Descargar PDF</a>.</p>
          </object>
        ) :
          <AspectRatio ratio={16 / 9}>
            <Image src={selectedWitnessesMonth?.files?.[currentIndex].file} />
          </AspectRatio>
        }
      </Box>
      <CommentsPanel />
      <BillingapproveModal />
    </Flex>
  )
}

export default DocumentsCommentsView