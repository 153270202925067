import { Apis } from "@/apis"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { CampaignStatus, CurrentStatus } from "@/modules/campaigns/enums/campaign.enum"
import { CampaignItem, subSectionList } from "@/modules/campaigns/models/campaign.model"
import { ConciliationItems } from "../models/conciliation.model"

export class ConciliationService {
  /** */
  private http = new HttpClient(`${Apis.ORKESTAMEDIA}/api/v1/conciliationSection`)

  public getCampaigByPlanning(planningSectionId: string): Promise<GenericApiResponse> {
    return this.http.get(`/planningSectionId/${planningSectionId}`)
  }

  public getConciliationBySection(sectionId: string): Promise<GenericApiResponse> {
    return this.http.get(`/sectionId/${sectionId}`)
  }

  public createCampaign(body: CampaignItem): Promise<GenericApiResponse> {
    return this.http.put('', body)
  }

  public createCampaignBriefNewComment(campaignSectionId: string, comment: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/briefFile/comment/${comment}`, {})
  }

  public createCampaignBriefFile(campaignSectionId: string, fileId: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/briefFile/fileId/${fileId}`, {})
  }

  public createCampaignStrategyNewComment(campaignSectionId: string, comment: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/strategyFile/comment/${comment}`, {})
  }

  public createCampaignStrategyFile(campaignSectionId: string, fileId: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/strategyFile/fileId/${fileId}`, {})
  }


  public updateConciliation(body: ConciliationItems): Promise<GenericApiResponse> {
    return this.http.patch('', body)
  }

  public updateCampaignCurrentStatusBrief(campaignSectionId: string, currentStatus: CurrentStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/briefFile/currentStatus/${currentStatus}`, {})
  }

  public updateCampaignImage(campaignSectionId: string, extension: string, imageId: string): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/imageId/${imageId}/extension/${extension}`, {})
  }

  public updateCampaignStatus(campaignSectionId: string, status: CampaignStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/status/${status}`, {})
  }

  public updateCampaignCurrentStatusStrategy(campaignSectionId: string, currentStatus: CurrentStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/strategyFile/currentStatus/${currentStatus}`, {})
  }

  public updateCampaignSubSection(campaignSectionId: string, subSection: subSectionList): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/subSection`, subSection)
  }

  public updateCampaignSubSectionList(campaignSectionId: string, subSection: subSectionList): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/subSectionList`, subSection)
  }
  public insertConciliationMount(conciliationSectionId: string, fileId: string, amount: number): Promise<GenericApiResponse> {
    return this.http.patch(`/amountConciliationLetterFile/${conciliationSectionId}/fileId/${fileId}`, amount)
  }

  public insertPurchaseOrderMount(conciliationSectionId: string, fileId: string, amount: number): Promise<GenericApiResponse> {
    return this.http.patch(`/amountPurchaseOrderFile/${conciliationSectionId}/fileId/${fileId}`, amount)
  }

}