import AccordionBadge from '@/core/components/molecules/AccordionBadge'
import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import { useComponentsStore } from '@/core/stores/components.store';
import { Accordion, Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useConciliationStore } from '../stores/conciliation.store';
import FileCard from '@/core/components/molecules/FileCard';
import { useNavigate } from 'react-router-dom';
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store';
import { BillingFiles, EvidencesFiles } from '../models/conciliation.model';
import { useModalStore } from '@/core/stores/modal.store';
import BillingAddNewModal from '../components/BillingAddNewModal';

function BillingListPage() {
  const navigate = useNavigate()
  const openModal = useModalStore(e => e.openModal)
  const { uiConciliationComponents } = useComponentsStore();
  const { SelectedBillingProvider, setSelectedFile, setSelectedBillingMonth } = useConciliationStore()
  const { selectedCampaign } = useCampaignStore()

  const handleClick = (file: BillingFiles, month: string) => {
    setSelectedFile(file)
    setSelectedBillingMonth(month)
    navigate('/views/reconciliations/billing-list/billing-view')
  }

  return (
    <>
      {uiConciliationComponents.seeBillingProviders &&
        <BrandPageHead title='Facturas de' subtitle={SelectedBillingProvider?.provider}>
          <Flex gap={2} alignItems={'center'}>
            <Text fontWeight="bold">Campaña:</Text>
            <Text>{selectedCampaign?.name}</Text>
          </Flex>
        </BrandPageHead>
      }
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='lg' fontWeight='semibold'>Listado de Facturas</Text>
        <Button onClick={() => openModal('BillingAddNewModal')}>Añadir factura</Button>
      </Flex>
      <Accordion allowToggle defaultIndex={[0]}>
        {
          SelectedBillingProvider?.billingsByMonth?.map((billing, index) => (
            <AccordionBadge title={billing.month} key={index}>
              <SimpleGrid alignItems='start' columns={{ base: 1, lg: 2 }} spacing={4}>
                {billing.files.map((file, index) => (
                  <Box key={index} cursor={file.type === 'xml' ? 'default' : 'pointer'} onClick={() => file.type === 'pdf' ? handleClick(file, billing.month) : {}}>
                    <FileCard typeFile={file.type} nameFile={file.name} comments={file.comments} status={billing.status} />
                  </Box>
                ))}
              </SimpleGrid>
            </AccordionBadge>
          ))
        }
      </Accordion>
      <BillingAddNewModal />
    </>
  )
}

export default BillingListPage