import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { BillingFiles, BillingProps, BuyOrder, ConciliationBilling, ConciliationItems, ConciliationLetter, EvidencesByMonth, EvidencesFiles, FileRevisable, WitnessesProviders } from "../models/conciliation.model";
import { ConciliationService } from "../services/conciliation.service";

const conciliationService = new ConciliationService()

/** Contrato del Store */
interface ConciliationState {
  witnessesProviders: WitnessesProviders[]
  buyOrders: BuyOrder[]
  conciliationItems: ConciliationItems[]
  selectedConciliation?: ConciliationItems
  conciliationLetter: ConciliationLetter[]
  conciliationBilling: ConciliationBilling[]
  selectedWitnessesProvider: WitnessesProviders | null
  selectedWitnessesMonth: EvidencesByMonth | null
  selectedFile: EvidencesFiles | BillingFiles | null
  SelectedBillingProvider: ConciliationBilling | null
  selectedBillingMonth: BillingProps | null
  selectedBuyOrderProvider: BuyOrder | null
  selectedLetterProvider: ConciliationLetter | null
  selectedPurchaseOrder: FileRevisable | null
  selectedConciliationLetter: FileRevisable | null
  updateFolders: (idProvider: string, nameFolder: string, evidencesNumber: number) => void
  updateBillings: (idProvider: string, nameFolder: string, files: BillingFiles[]) => void
  retrieveConciliationItems: (sectionId: string) => Promise<boolean>
  setConciliationSelected: (item: ConciliationItems) => void
  setSelectedWitnessesProvider: (provider: string) => void
  setSelectedWitnesses: (witnesses: WitnessesProviders[]) => void
  setSelectedWitnessesMonth: (month: string) => void
  setSelectedBillings: (billings: ConciliationBilling[]) => void
  setSelectedBillingProvider: (provider: string) => void
  setSelectedBillingMonth: (month: string) => void
  setSelectedBuyOrderProvider: (provider: string) => void
  setPurchaseOrder: (purchaseOrder: FileRevisable) => void
  setConciliationLetter: (conciliationLetter: FileRevisable) => void
  setSelectedLetterProvider: (provider: string) => void
  setSelectedFile: (file: EvidencesFiles | BillingFiles) => void
  updatePurcharseOrders: (fileId: string) => Promise<boolean>
  updateConciliationLetter: (fileId: string) => Promise<boolean>
  updatePuchaseOrderComments: (item: ConciliationItems) => Promise<boolean>
  updateConciliationLetterComments: (item: ConciliationItems) => Promise<boolean>
  insertConciliationMount: (conciliationSectionId: string, fileId: string, amount: number) => Promise<boolean>
  insertPurchaseOrderMount: (conciliationSectionId: string, fileId: string, amount: number) => Promise<boolean>
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  selectedBillingMonth: null,
  selectedFile: null,
  selectedWitnessesProvider: null,
  selectedWitnessesMonth: null,
  SelectedBillingProvider: null,
  selectedBuyOrderProvider: null,
  selectedLetterProvider: null,
  selectedPurchaseOrder: null,
  selectedConciliationLetter: null,
  witnessesProviders: [
    {
      provider: "TV AZTECA",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Octubre 2024',
          files: [
            {
              name: 'Post Kivit',
              file: '/conciliation/fb1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post 2 Kivit',
              file: '/conciliation/fb2.jpeg',
              id: '974K89',
              type: 'image'
            },
          ]
        },
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Septiembre 2024',
          files: [
            {
              name: 'Post Kivit',
              file: '/conciliation/kivit1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post 2 Kivit',
              file: '/conciliation/kivit2.jpeg',
              id: '974K89',
              type: 'image'
            },
          ]
        },
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Post Kivit',
              file: '/conciliation/kivit3.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post 2 Kivit',
              file: '/conciliation/kivit4.jpeg',
              id: '974K89',
              type: 'image'
            },
          ]
        },
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Julio 2024',
          files: [
            {
              name: 'Post Kivit',
              file: '/conciliation/kivit5.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post 2 Kivit',
              file: '/conciliation/kivit6.jpeg',
              id: '974K89',
              type: 'image'
            },
          ]
        }

      ]
    },
    {
      provider: "Televisa",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Tiktok media',
              file: '/conciliation/tiktok1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Tiktok media 2',
              file: '/conciliation/tiktok2.jpeg',
              id: '974K90',
              type: 'image'
            },
          ]
        }

      ]
    },
    {
      provider: "MTV",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Página 13 revista',
              file: '/conciliation/revista1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Página 73 revista',
              file: '/conciliation/revista2.jpeg',
              id: '974K90',
              type: 'image'
            }
          ]
        }

      ]
    },
    {
      provider: "Multimedios",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Mayo 2024',
          files: [
            {
              name: 'Post instagram 1',
              file: '/conciliation/insta1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post instagram 2',
              file: '/conciliation/insta2.jpeg',
              id: '974K90',
              type: 'image'
            },
          ]
        }

      ]
    },
  ],
  buyOrders: [
    {
      tvType: 'TVA',
      provider: 'Facebook',
      folio: '7jhb56',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Orden compra2.0", type: "pdf" },
        { file: '/conciliation/Factura PTV.pdf', name: "Orden compra2.0", type: "pdf" },
        { file: '/conciliation/Factura PTV2.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'TikTok',
      folio: '8kij23',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'Revista emprendedores',
      folio: '9lmn45',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'Instagram',
      folio: '1xyz78',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
  ],
  conciliationLetter: [
    {
      provider: "Facebook", fileName: "conciliacion_tv_azteca.pdf", tvType: 'TVA',
      versions: [
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "TikTok", fileName: "conciliacion_imagen_television.pdf", tvType: 'TVA',
      versions: [
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "Revista emprendedores", fileName: "conciliacion_azteca_trece.pdf", tvType: 'TVA',
      versions: [
        { file: '/conciliation/Factura PTV2.pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/conciliation/Factura PTV.pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "Instagram", fileName: "conciliacion_azteca_trece.pdf", tvType: 'TVA',
      versions: [
        { file: '/conciliation/Factura PTV2.pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/conciliation/Factura PTV.pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
  ],
  conciliationBilling: [
    {
      provider: "TV Azteca",
      numberBillings: 2,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Octubre 2024",
          files: [
            { file: '/conciliation/Factura TV Azteca.pdf', name: "Factura Tv Azteca", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "CFDI Tv Azteca", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Septiembre 2024",
          files: [
            { file: '/conciliation/Factura Youtube.pdf', name: "Factura youtube", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "CFDI youtube", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Agosto 2024",
          files: [
            { file: '/conciliation/Factura Medio.pdf', name: "Factura Medios PTV", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "CFDI Medios PTV", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "TikTok",
      numberBillings: 9,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "Revista emprendedores",
      numberBillings: 5,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "Instagram",
      numberBillings: 3,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
  ],
  conciliationItems: [],
  selectedConciliation: undefined
}

/** Definición del Store */
const Store: StateCreator<ConciliationState> = (set, get) => ({

  ...initialState,

  setSelectedWitnessesProvider: (provider: string) => {
    const witnessesProviders = get().witnessesProviders;

    const providerSelected = witnessesProviders.filter((e) => e.provider === provider)[0]
    set({ selectedWitnessesProvider: providerSelected })
  },

  setSelectedWitnessesMonth: (month: string) => {
    const witnessesMonth = get().selectedWitnessesProvider;

    const monthSelected = witnessesMonth?.evidences?.filter((e) => e.month === month)[0]
    set({ selectedWitnessesMonth: monthSelected })
  },

  setSelectedFile: (file: EvidencesFiles | BillingFiles) => {
    set({ selectedFile: file })
  },

  setSelectedWitnesses: (witnesses: WitnessesProviders[]) => {
    set({ witnessesProviders: witnesses })
  },

  setSelectedBillings: (billings: ConciliationBilling[]) => {
    set({ conciliationBilling: billings })
  },

  setSelectedBuyOrderProvider: (provider: string) => {
    const billing = get().buyOrders;
    //const purchaseOrders = get().selectedConciliation?.purchaseOrderList?.[0]

    const providerSelected = billing.filter((e) => e.provider === provider)[0]
    console.log(providerSelected)
    set({ selectedBuyOrderProvider: providerSelected, selectedFile: providerSelected.versions[0] })
  },

  setSelectedBillingProvider: (provider: string) => {
    const conciliationBilling = get().conciliationBilling;

    const providerSelected = conciliationBilling.filter((e) => e.provider === provider)[0]
    set({ SelectedBillingProvider: providerSelected })
  },

  setSelectedBillingMonth: (month: string) => {
    const conciliationBilling = get().SelectedBillingProvider;

    const providerSelected = conciliationBilling?.billingsByMonth.filter((e) => e.month === month)[0]
    set({ selectedBillingMonth: providerSelected })
  },

  setSelectedLetterProvider: (provider: string) => {
    const conciliationBilling = get().conciliationLetter;

    const providerSelected = conciliationBilling.filter((e) => e.provider === provider)[0]
    set({ selectedLetterProvider: providerSelected, selectedFile: providerSelected.versions[0] })
  },

  setPurchaseOrder: (purchaseOrder: FileRevisable) => {
    set({ selectedPurchaseOrder: purchaseOrder })
  },

  setConciliationLetter: (conciliationLetter: FileRevisable) => {
    set({ selectedConciliationLetter: conciliationLetter })
  },

  updateFolders: (idProvider: string, nameFolder: string, evidencesNumber: number) => {
    let items = get().witnessesProviders
    const witnesses = items.map((e) => {
      if (e.provider === idProvider) {
        console.log(e)
        return {
          ...e,
          evidences: [
            ...e?.evidences!,
            {
              evidencesNumber: evidencesNumber,
              month: nameFolder,
              status: 'Pendiente',
              files: []
            }
          ]
        }
      } else {
        return e
      }
    })
    set({ witnessesProviders: witnesses, selectedWitnessesProvider: witnesses.find(e => e.provider === idProvider) })
  },

  updateBillings: (idProvider: string, nameFolder: string, files: BillingFiles[]) => {
    let items = get().conciliationBilling
    console.log(items)
    const billings = items.map((e) => {
      if (e.provider === idProvider) {
        console.log(e)
        return {
          ...e,
          provider: e.provider,
          billingsByMonth: [
            ...e.billingsByMonth,
            {
              status: 'Bajo revisión',
              month: nameFolder,
              files: files
            }
          ]
        }
      } else {
        return e
      }
    })
    console.log(billings)
    set({ conciliationBilling: billings, SelectedBillingProvider: billings?.find(e => e.provider === idProvider) })
  },

  retrieveConciliationItems: (sectionId) => {
    return new Promise((resolve, reject) => {
      conciliationService.getConciliationBySection(sectionId).then(
        response => {
          set({ conciliationItems: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  setConciliationSelected: (item: ConciliationItems) => {
    set({ selectedConciliation: item })
  },

  updatePurcharseOrders: (fileId: string) => {
    return new Promise((resolve, reject) => {
      const item = get().selectedConciliation
      const newFile: ConciliationItems = {
        ...item,
        purchaseOrderList: [
          ...item?.purchaseOrderList!,
          {
            fileId: fileId
          }
        ]
      }
      conciliationService.updateConciliation(newFile).then(
        response => {
          const newItems: ConciliationItems[] = get().conciliationItems
          const newPurchaseOrder: ConciliationItems = response.respuesta
          newItems.push(newPurchaseOrder)
          set({
            conciliationItems: newItems, selectedConciliation: response.respuesta, selectedPurchaseOrder: newPurchaseOrder?.purchaseOrderList && newPurchaseOrder?.purchaseOrderList[newPurchaseOrder?.purchaseOrderList?.length - 1]
          })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  updateConciliationLetter: (fileId: string) => {
    return new Promise((resolve, reject) => {
      const item = get().selectedConciliation
      const newFile: ConciliationItems = {
        ...item,
        conciliationLetterList: [
          ...item?.conciliationLetterList!,
          {
            fileId: fileId
          }
        ]
      }

      conciliationService.updateConciliation(newFile).then(
        response => {
          const newItems: ConciliationItems[] = get().conciliationItems
          newItems.push(response.respuesta)
          const lastItem: ConciliationItems = response.respuesta
          set({
            conciliationItems: newItems, selectedConciliation: response.respuesta, selectedConciliationLetter: lastItem?.conciliationLetterList && lastItem.conciliationLetterList[lastItem.conciliationLetterList.length - 1]
          })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  updatePuchaseOrderComments: (item: ConciliationItems) => {
    return new Promise((resolve, reject) => {
      conciliationService.updateConciliation(item).then(
        response => {
          const newItem: ConciliationItems = response.respuesta;
          const selectedPurchaseOrder = get().selectedPurchaseOrder
          set({ selectedConciliation: newItem, selectedPurchaseOrder: newItem.purchaseOrderList?.find(e => e.fileId === selectedPurchaseOrder?.fileId) })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  updateConciliationLetterComments: (item: ConciliationItems) => {
    return new Promise((resolve, reject) => {
      conciliationService.updateConciliation(item).then(
        response => {
          const newItem: ConciliationItems = response.respuesta;
          const selectedConciliationLetter = get().selectedConciliationLetter
          set({ selectedConciliation: newItem, selectedConciliationLetter: newItem.conciliationLetterList?.find(e => e.fileId === selectedConciliationLetter?.fileId) })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  insertConciliationMount: (conciliationSectionId: string, fileId: string, amount: number) => {
    return new Promise((resolve, reject) => {
      conciliationService.insertConciliationMount(conciliationSectionId, fileId, amount).then(
        response => {
          const newItem: ConciliationItems = response.respuesta;
          const lastItem = newItem.conciliationLetterList
          set({ selectedConciliation: newItem, selectedConciliationLetter: lastItem?.find(e => e.fileId === fileId) })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  insertPurchaseOrderMount: (conciliationSectionId: string, fileId: string, amount: number) => {
    return new Promise((resolve, reject) => {
      conciliationService.insertPurchaseOrderMount(conciliationSectionId, fileId, amount).then(
        response => {
          const newItem: ConciliationItems = response.respuesta;
          const lastItem = newItem.purchaseOrderList
          set({ selectedConciliation: newItem, selectedPurchaseOrder: lastItem?.find(e => e.fileId === fileId) })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useConciliationStore = create<ConciliationState>()(
  immer(Store)
)