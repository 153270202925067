import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import ConciliationSectionLayout from "./pages/conciliation-section/ConciliationSectionLayout"
import ConciliationSectionRouter from "./pages/conciliation-section/ConciliationSection.routes";
import ConciliationWitnessesPage from "./pages/ConciliationWitnessesPage";
import DocumentViewTemplate from "./components/DocumentViewTemplate";
import WitnessesEvidencesPage from "./components/WitnessesEvidencesPage";
import DocumentsViewTemplate from "./components/DocumentsViewTemplate";

const ConciliationPage = lazy(() => import('./pages/ConciliationPage'))
const BillingListPage = lazy(() => import('./pages/BillingListPage'))

const ConciliationRoutes: RouteObject[] = [
  { path: '', element: <ConciliationPage /> },
  {
    path: 'detail',
    element: <ConciliationSectionLayout />, 
    children: [
      { index: true, element: <Navigate to="witnesses" replace /> },
      ...ConciliationSectionRouter
    ]
  },
  { path: 'witnesses', element: <ConciliationWitnessesPage /> },
  { path: 'witnesses/evidences', element: <WitnessesEvidencesPage /> },
  { path: 'witnesses/evidences/document-view', element: <DocumentsViewTemplate key='evidences' isCarousel={true} /> },
  { path: 'conciliation-letter', element: <DocumentViewTemplate title='Carta conciliación' /> },
  { path: 'billing-list', element: <BillingListPage /> },
  { path: 'buy-order', element: <DocumentViewTemplate title='Orden' /> },
  { path: 'billing-list/billing-view', element: <DocumentViewTemplate title='Factura' /> },
]

export default ConciliationRoutes