import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { FiMapPin } from 'react-icons/fi'
import { LuTv } from 'react-icons/lu'
import { BsCurrencyDollar } from 'react-icons/bs'
import { ProviderList } from '@/core/models/planning.model'
import { DataUtil } from '@/core/utils/data.util'

const BudgetCards = ({ data }: { data: ProviderList }) => {
  return (
    <Flex direction="column" border="1px solid" borderColor="gray.200" borderRadius="md" overflow="hidden">
      <Box bg="brand.500" color="white" p={4} minHeight="80px" display="flex" alignItems="center" justifyContent="center" textAlign='center'>
        {data.provider}
      </Box>
      <Flex flex={1} p={4}>
        <Box>
          <Box mb={3}>
            <Text display='flex' alignItems='center' fontWeight='bold' gap={1} fontSize='sm'><FiMapPin /> Plazas:</Text> {data?.zoneList?.map(e => <Text>{e}</Text>)}
          </Box>
          <Box mb={3}>
            <Text display='flex' alignItems='center' fontWeight='bold' gap={1} fontSize='sm'><LuTv /> Tipos de medio:</Text> {data?.nameList?.map(e => <Text>{e}</Text>)}
          </Box>
          {/* <Text display='flex' alignItems='center' fontWeight='bold' gap={1} fontSize='sm'><LuTv /> Medio: {data.medio}</Text> */}
          {/* <Text display='flex' alignItems='center' fontWeight='bold' gap={1} fontSize='sm'><FiBriefcase /> Tipo de negociación: {data.payTypeList?.map(e => e)}</Text> */}
          <Box mb={3}>
            <Text display='flex' alignItems='center' fontWeight='bold' gap={1} fontSize='sm'><BsCurrencyDollar /> Tipos de pago:</Text> {data.payTypeList?.map(e => <Text>{e}</Text>)}
          </Box>
        </Box>
      </Flex>
      <Box bg="gray.100" p={4} mt="auto">
        <Text align='center' my={1} fontWeight='bold' fontSize='md'>Presupuesto</Text>
        <Divider my={2} />
        <Box>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm' fontWeight='bold'>Monto total</Text>
            <Text fontSize='sm'>
              {
                DataUtil.numberCurrencyFormat(data?.amount!)
              }
            </Text>
          </Flex>
          {/* <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Dinero fresco</Text>
            <Text fontSize='sm'>$893,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Ahorro</Text>
            <Text fontSize='sm'>$3,993,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Carry over</Text>
            <Text fontSize='sm'>$19,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Capacidad de compra 1</Text>
            <Text fontSize='sm'>$9,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Bonificables JV/TL</Text>
            <Text fontSize='sm'>$199,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Retornos JVALE 2021-22</Text>
            <Text fontSize='sm'>$303,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Retornos JVA</Text>
            <Text fontSize='sm'>$1,200,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Capacidad de compra 2</Text>
            <Text fontSize='sm'>$20,000.98</Text>
          </Flex> */}
        </Box>
      </Box>
      {/* <Text align='center' my={1}>Compensación</Text>
        <Divider my={2} />
        <Box>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Cuenta por pagar
            </Text>
            <Text fontSize='sm'>
              $10,000.98
            </Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Carry over bonificable
            </Text>
            <Text fontSize='sm'>
              $9,000.98
            </Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Carry over bonificable (mes)
            </Text>
            <Text fontSize='sm'>-</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Facturable (mes)
            </Text>
            <Text fontSize='sm'>-</Text>
          </Flex>
        </Box> */}
    </Flex>
  )
}

export default BudgetCards